@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* --- global --- */
html {
  font-family: Roboto;
  
}
body {
  @apply bg-white dark:bg-black-main overflow-x-hidden;
  @apply text-word-light dark:text-word-dark;
  
  /* !!! to avoid sweetalert2 plugin auto padding right !!! */
  padding-right: 0 !important;
}

h1, h2, h3 {
  @apply font-bold tracking-[0.015rem];
}
h1 {
  @apply text-[2.25rem] md:text-[3rem] 2xl:text-[4rem];
}
h3 {
  font-size: 1.5rem;
}
a {
  @apply hover:text-sub-blue hover:dark:text-sub-blue;
}
input {
  @apply outline-none bg-transparent;
}

.w-limit {
  @apply mx-auto min-w-[320px] max-w-[600px] lg:max-w-[800px];
}
.w-limit-min {
  @apply mx-auto min-w-[320px];
}
.w-limit-max {
  /* menu show above lg (240px) */
  @apply mx-auto min-w-[320px] md:max-w-[700px] xl:max-w-[960px] 2xl:max-w-[1200px] 3xl:max-w-[1400px] 4xl:max-w-[1600px];
}

.text-default {
  @apply text-word-light dark:text-word-dark;
}
.text-blue {
  @apply text-sub-blue dark:text-sub-blue;
}
.text-sub {
  @apply text-sub-word dark:text-sub-word;
}
.text-reverse {
  @apply text-word-dark dark:text-word-light;
}
.text-fail {
  @apply text-alert dark:text-alert;
}
.text-pass {
  @apply text-alert-green dark:text-alert-green;
}
.text-gray {
  @apply text-[#666] dark:text-[#666];
}

.bg-none {
  @apply bg-transparent dark:bg-transparent;
}
.bg-theme {
  @apply bg-primary dark:bg-primary;
}
.bg-blue {
  @apply bg-sub-blue dark:bg-sub-blue;
}
.bg-sub {
  @apply bg-sub-word dark:bg-sub-word;
}
.bg-666 {
  @apply bg-[#666] dark:bg-[#666];
}
.bg-main {
  @apply bg-sub-white dark:bg-black;
}
.bg-menu {
  @apply bg-white-menu dark:bg-black-menu;
}
.bg-pass {
  @apply bg-alert-green dark:bg-alert-green;
}
.bg-fail {
  @apply bg-alert dark:bg-alert;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: #55CCFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

/* --- hide number span --- */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* ------------------------ */

/* select {
  background: transparent;
} */
/* select.noArrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow:'';
  text-indent: 0.01px;
  text-overflow: "";
} */
/* form select {
  @apply block w-full px-2 py-2 bg-white dark:bg-black-main rounded border border-[#666] cursor-pointer;
} */

/* overwrite swiper */
span.swiper-pagination-bullet {
  background: #EEE;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  @apply bg-sub-button;
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.active.tabOnSelect {
  position: relative;
  color: #88DCFF;
}
.active.tabOnSelect:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: #88DCFF;
  border-radius: 10px;
}

.hideScrollbar {
  overflow: auto;
  -webkit-appearance: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.hideScrollbar::-webkit-scrollbar {
  display: none; /* safari */
}

/* overwrite sweetalert */
.swal2-html-container {
  @apply text-left text-black-main dark:text-[#ccc];
  text-align: center;
  
}
.swal2-title {
  @apply text-sub-black dark:text-[#ddd] tracking-[1px];
}
.swal2-popup {
  @apply bg-white-main dark:bg-black-main;
}
.swal2-styled.swal2-confirm {
  @apply bg-sub-blue text-word-dark dark:text-word-light;
}
.swal2-styled.swal2-confirm.system {
  @apply bg-[#1976d2] text-word-dark dark:text-word-light;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
  outline: none;
}
.swal2-styled.swal2-cancel {
  @apply bg-sub-word text-word-dark dark:text-word-light;
}
.swal2-styled.swal2-cancel:focus {
  box-shadow: none;
  outline: none;
}
.swal2-popup.w-auto {
  width: auto;
}

@keyframes swal2-show2 {
  0% {
    transform: translate(-50%, -50%) scale(0.7);
  }
  45% {
    transform: translate(-50%, -50%) scale(1.05);
  }
  80% {
    transform: translate(-50%, -50%) scale(0.95);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes swal2-hide2 {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
}