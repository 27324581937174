@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');

#landing * {
  box-sizing: content-box;
  /* font-family: 'Poppins', 'Noto Sans TC', sans-serif; */
  /* font-weight: 500; */
  letter-spacing: 1px;
  /* text-align: center; */
}

#landing .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#landing .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#landing .App-link {
  color: #61dafb;
}

#landing input {
  outline: none;
}

#landing img {
  display: inline-block;
  width: 100%;
}

#landing .swiper {
  width: 100%;
  height: 100%;
  
}

#landing .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  height: 100%;
  align-items: stretch;
}

#landing .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#landing .swiper-pagination-bullet-active {
  background-color: #0AECD1!important;
}

#landing .swiper-pagination-bullets {
  bottom: 0px!important;

}

#landing .blink {
  animation: fade 1.5s infinite;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
    0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes float {
  50% {
     transform: translate(0, 20px);
  }
}

@keyframes fade {
    0% { opacity: 0.2; }
   50% { opacity: 0.1; }
  100% { opacity: 0.4; }
}

@keyframes AnimeGrade {
    0% { background-position:   0% 50% }
   50% { background-position: 100% 50% }
  100% { background-position:   0% 50% }
}

@keyframes mymove {
    0% { background-color: #24243e; }
   50% { background-color: #302b63; }
  100% { background-color: #151944; }
}

@keyframes gradient {
    0% { background-position:   0% 50%; }
   50% { background-position: 100% 50%; }
  100% { background-position:   0% 50%; }
}
 
