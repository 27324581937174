input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fcfcfc;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: none !important;
  }
  
  .react-datepicker__triangle::after {
    border-bottom-color: white !important;
  }
  
  .react-datepicker__day--selected {
    background-color: #0AECD1 !important;
  }
  
  .react-datepicker__day--keyboard-selected {
    background-color: white !important;
    color: black !important;
    
  }
  
  .react-datepicker__day--today {
  background-color: none !important;
  }


  .react-tooltip {
    z-index: 1 !important;
  }


  /* ::-webkit-scrollbar{
    width: 12px;
  background-color: black;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #151944;
  }
  ::-webkit-scrollbar-thumb{
    background-color: #22254a;
  } */











